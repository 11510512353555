<template>
  <div>
    <div class="container">
      <div class="example">
        <a-spin :spinning="loading">
          <a-row type="flex">
            <a-col :md="6"  class="right scroller">
              <!--组织树-->
              <div class="tree-top">
                <span>参数类型</span>
                <a-icon type="plus" class="tree-add" @click="append" />
              </div>
              <a-tree
                default-expand-all
                :tree-data="treeData"
                :selectedKeys.sync="currentNode"
                :replaceFields="{children:'children', title:'typeName', key:'key' }"
                :key="treeDataKey"
                @select="onSelect"
              >
                <template slot="custom" slot-scope="custom">
                  <span>{{ custom.dataRef.typeName }}</span>
                  <!--                <a-icon type="plus" class="tree-item-add" @click="append"/>-->
                  <a-icon type="edit" class="tree-edit" @click="edit(custom.dataRef)" />
                  <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => del(custom.dataRef)">
                    <a-icon type="delete" class="tree-delete" />
                  </a-popconfirm>
                </template>
              </a-tree>
            </a-col>
            <a-col :md="18" >
              <!--组织表格-->
              <param-table v-if="currentNode.length>0" :current-id="currentNode[0]+''" ref="table" />
            </a-col>
          </a-row>
        </a-spin>
      </div>
      <type-modal ref="dirAction" @ok="handleDirTree"></type-modal>
    </div>
  </div>
</template>

<script>
import ParamTable from './components/ParamTable'
import TypeModal from './components/TypeModal'
import { getDirTree, delDir } from '@/api/system/dict'

export default {
  name: 'Index',
  components: {
    ParamTable,
    TypeModal
  },
  data () {
    return {
      // 树
      treeData: [],
      // 选中的节点
      currentNode: [],
      // 缓存选中
      cookieCurrent: [],
      loading: false,
      treeDataKey: 1
    }
  },
  mounted () {
    this.handleDirTree()
  },
  methods: {
    /**
     * 获取组织树信息
     */
    handleDirTree () {
      this.loading = true
      getDirTree({ parentId: 0 }).then((res) => {
        if (res['code'] === 200) {
          this.createdScopedSlots(res.data)
          this.treeData = res.data
          // 默认选择第一个
          this.currentNode[0] = res.data[0].key
          // 缓存
          this.cookieCurrent[0] = res.data[0].key
          this.treeDataKey++
        }
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     *选择组织
     */
    onSelect () {
      // 第二次选择为空，将缓存给它
      if (this.currentNode.length < 1) {
        this.currentNode = this.cookieCurrent
      } else {
        this.cookieCurrent[0] = JSON.parse(JSON.stringify(this.currentNode[0]))
      }
      // 下一个生命周期
      this.$nextTick(() => {
        this.refreshTable()
      })
    },
    /**
     * 刷新表格 currentNode部门key===部门id
     * @param
     */
    refreshTable () {
      this.$refs['table'].deptRefresh()
    },
    append (item) {
      this.$refs['dirAction'].append(item)
    },
    edit (item) {
      this.$refs['dirAction'].edit(item)
    },
    del (record) {
      delDir({ id: record.id }).then((res) => {
        if (res.code === 200) {
          this.$message.success('删除成功')
          this.handleDirTree()
        } else {
          this.$message.error('删除失败：' + res.msg)
        }
      }).catch((err) => {
        this.$message.error('删除错误：' + err.msg)
      })
    },
    /**
     * 创建ScopedSlots
     * @param data
     */
    createdScopedSlots (data) {
      for (let i = 0; i < data.length; i++) {
        data[i].scopedSlots = { title: 'custom' }
        if (data[i].children) {
          this.createdScopedSlots(data[i].children)
        }
      }
    }
  }
}
</script>

<style scoped>
.container {
  background: #fff;
  padding: 8px;
}

.right {
  border-right: 1px solid #e8e8e8;
}

.tree-top {
  position: relative;
}

.tree-top span{
  font-weight: bold;
}

.tree-add {
  position: absolute;
  right: 6%;
}

.tree-item-add {
  position: absolute;
  right: 22%;
  font-size: 12px;
}

.tree-edit {
  position: absolute;
  right: 13%;
  font-size: 12px;
}

.tree-delete {
  position: absolute;
  right: 4%;
  font-size: 12px;
}
</style>
